import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/operate.module.css"
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import InfopTable from "../table/InfopTable";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";
import moment from "moment"
const CallInfoWrap = () => {
    const { idx } = useParams()
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [status, setStatus] = useState("");


    const [stopDate, setStopDate] = useState("");
    const [routeName, setRouteName] = useState("");
    const [routeMemo, setRouteMemo] = useState("");




    const [adminName, setAdminName] = useState("");
    const [mobile, setMobile] = useState("");
    const [stopDirect, setStopDirect] = useState("");
    const [pass, setPass] = useState("");


    useEffect(() => {

        if (idx !== "0") {
            let sendData = {
                user_uniq: idx
            };

            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/operCall_detail_info",
                sendData,
                function (result) {
                    if (result.messageinfo) {
                        setStopDate(moment(result.messageinfo.message[0].stop_date).format("YYYY-MM-DD HH:mm:ss"));
                        setAdminName(result.messageinfo.message[0].user_name);
                        setMobile(result.messageinfo.message[0].user_mobile);
                        setStopDirect(result.messageinfo.message[0].user_id);
                        setStatus(result.messageinfo.message[0].user_status)
                    }
                })
        }
    }, [])





    const password_test = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$~!%*#?&])[A-Za-z\d@$~!%*#?&]{8,20}$/
    const id_test = /^[A-Za-z0-9]{4,}$/
    const btn_save = () => {
        if (idx === '0') {
            if (!password_test.test(pass)) {
                window.alert("8~20자리 영문,숫자,특수문자 혼합하여 비밀번호를 입력해주세요.")
                return;

            }
        }
        if (idx !== '0') {
            if (pass !== "" && !password_test.test(pass)) {
                window.alert("8~20자리 영문,숫자,특수문자 혼합하여 비밀번호를 입력해주세요.")
                return;

            }
        }
        if (!stopDirect || !id_test.test(stopDirect)) {
            window.alert("4자리 이상 영문.숫자 혼합하여 아이디를 입력해주세요.")
            return;

        }
        let sendData = {
            user_name: adminName,
            user_mobile: mobile,
            user_id: stopDirect,
            password: pass,
            user_status: 1,
            user_uniq: "",
            // stop_connection: stopCon,

        }

        let record = {
            record_category: "콜센터담당자관리",
            record_user: user.uniq,
            record_text: `아이디 ''${stopDirect}'' 콜센터 담당자를 등록하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        if (idx === '0') {
            CommonAxiosToken(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/operCall_insert",
                sendData,
                function (result) {

                    if (result.messageinfo) {
                        if (result.messageinfo.message === "이미 사용하고 있는 아이디가 있습니다.") {
                            window.alert("이미 사용하고 있는 아이디가 있습니다.")
                        } else {
                            AdminRecordAxios(record);
                            window.alert("저장 완료");
                            navigate(`/operatemg/call`);
                        }


                    }


                })

        } else {
            sendData.user_status = status;
            sendData.user_uniq = idx;
            let record = {
                record_category: "콜센터담당자관리",
                record_user: user.uniq,
                record_text: `아이디 ''${stopDirect}'' 콜센터 담당자를 수정하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxiosToken(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/operCall_update",
                sendData,
                function (result) {

                    if (result.messageinfo) {

                        AdminRecordAxios(record);
                        window.alert("수정 완료")
                        navigate(`/operatemg/call`);
                    }

                })
        }
    }
    const btn_name_save2 = () => {

        let sendData = {
            no: idx,
            route_info: routeMemo,

            type: 2,
        }

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/update_route_info",
            sendData,
            function (result) {

                if (result.result) {
                    window.alert("수정 완료")

                }

            })


    }

    const btn_insert = () => {

        let sendData = {
            value: routeName
        }

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_route",
            sendData,
            function (result) {
                if (result.result) {

                    navigate(`/busmg/routemg/routeInfo/${result.result}`)
                }

            })


    }
    const changeName = useCallback((e) => {
        setAdminName(e.target.value)
    }, [])
    const changeNumber = useCallback((e) => {
        setMobile(e.target.value)
    }, [])


    const changeDirect = useCallback((e) => {
        setStopDirect(e.target.value)
    }, [])
    const changePass = useCallback((e) => {
        setPass(e.target.value)
    }, [])
    const changeOs = (e) => {
        setStatus(e);

    }
    return (
        <>

            <div className={line.operate_call_info_wrap}>
                <div className={line.operate_call_info_box}>
                    <div className={line.operate_call_info_table}>
                        {idx !== '0' &&
                            <>
                                <div className={line.operate_call_info_row}>
                                    <span className={line.operate_call_info_span}>등록일</span>
                                    <div className={line.operate_call_info_input}>
                                        <p>{stopDate}</p>
                                    </div>
                                </div>
                                <div className={line.operate_call_info_row}>
                                    <span className={line.operate_call_info_span}>계정상태</span>
                                    <div className={line.operate_call_info_input}>
                                        <label style={{ marginRight: "5px" }}><input type="radio" name="device" onChange={(e) => changeOs(1)} checked={status === 1 ? true : false} /> 계정활성화 </label>
                                        <label style={{ marginRight: "5px" }}><input type="radio" name="device" onChange={(e) => changeOs(2)} checked={status === 2 ? true : false} /> 계정비활성화(잠김) </label>
                                    </div>
                                </div>

                            </>
                        }
                        <div className={line.operate_call_info_row}>
                            <span className={line.operate_call_info_span}>담당자명</span>
                            <div className={line.operate_call_info_input}>
                                <input type="text" value={adminName} onChange={changeName} />
                            </div>
                        </div>
                        <div className={line.operate_call_info_row}>
                            <span className={line.operate_call_info_span}>연락처</span>
                            <div className={line.operate_call_info_input}>
                                <input type="text" value={mobile} onChange={changeNumber} />
                            </div>
                        </div>
                        <div className={line.operate_call_info_row}>
                            <span className={line.operate_call_info_span}>아이디</span>
                            <div className={line.operate_call_info_input}>
                                <input type="text" value={stopDirect} onChange={changeDirect} disabled={idx === "0" ? false : true} />
                                <span style={{ marginLeft: "5px", fontSize: "14px" }}>4자리 이상 영문,숫자</span>

                            </div>
                        </div>
                        <div className={line.operate_call_info_row}>
                            <span className={line.operate_call_info_span}>비밀번호</span>
                            <div className={line.operate_call_info_input}>
                                <input type="password" value={pass} onChange={changePass} />
                                <span style={{ marginLeft: "5px", fontSize: "14px" }}>8~20자리 이상 영문,숫자,특수문자 혼합</span>

                            </div>
                        </div>
                    </div>

                    <div className={line.operate_call_save}>
                        <button onClick={btn_save}>저장</button>
                    </div>

                </div>

            </div></>)
}

export default CallInfoWrap